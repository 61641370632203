/* Built In Imports */
import { useContext, useEffect } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import Breadcrum from '@components/Navigation/Breadcrum';
import OthersCommonUI from '@components/UI/Others/OthersCommonUI';
import HeadComponent from '@components/Utility/HeadComponent';
import config from '@config';
import WrapLayout from '@Layout/WrapLayout';
import { AuthContext } from '@store/auth-context';

/* Services */
import {
    getLocale
} from '@components/Utility/Shared/SharedService';
import {
    fetchFooterData, fetchHeaderMenuData, fetchHeaderTopMenuData
} from 'services/commonService';
import { fetchDetails, getLandingPaths } from 'services/othersService';

/* Styles */

/**
 *
 * @param {string} region - Current User Region
 * @param {string} language - Current User Language
 * @param {string} pageData - Search Landing Page Data
 */
const YouthNTruth = ({ region, language, pageData, newsData }) => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.RegionAndLang(region, language);
  }, [authContext, region, language]);

  // consoleLog('page', pageData);

  return (
    <WrapLayout>
      <HeadComponent
        data={pageData?._seoMetaTags || pageData?.seoFields}
        availableLocals={pageData.availableLocales}
      >
        {' '}
      </HeadComponent>
      <Breadcrum
        page={pageData.breadcrumbs}
        alsoin={pageData.alsoin}
        url=""
        region={region}
        lang={language}
        pageConfig={pageData?.pageConfig}
      />
      <Box as="section" className="page-wrap container-article" p="0">
        <OthersCommonUI
          pageBody={pageData.body}
          region={region}
          lang={language}
        />
        {/* <Banner /> */}
        {/* <YouthBook /> */}
      </Box>
    </WrapLayout>
  );
};

export const getStaticPaths = async () => {
  const searchLandingPaths = config.USE_LOCAL_URL
    ? [`/in/en/youth-n-truth`, `/us/en/youth-n-truth`]
    : await getLandingPaths('lvl0', '/youth-n-truth');
  return {
    paths: searchLandingPaths,
    fallback: false,
  };
};

export const getStaticProps = async ({
  preview,
  previewData,
  params: { region, language },
  req,
}) => {
  const headerTopMenuData = await fetchHeaderTopMenuData(
    getLocale(region, language),
    'isotopmenu'
  );
  const headerMenuData = await fetchHeaderMenuData(
    getLocale(region, language),
    'isomainmenu'
  );
  const footerData = await fetchFooterData(
    getLocale(region, language),
    'isofooter'
  );
  const pageData = await fetchDetails(region, language, `youth-n-truth`, { preview, data: previewData });

  return {
    props: {
      headerTopMenuData: headerTopMenuData?.data || null,
      headerMenuData: headerMenuData?.data || null,
      region: region,
      language: language,
      footerData: footerData?.data || null,
      pageData: pageData || null,
      isPreview: preview ? true : false
    }
  };
};

export default YouthNTruth;
